<template>
  <div>
    <p class="mb-2">Which method(s) did you use to solve these problems?</p>

    <v-form @submit.prevent="submitResponse">
      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question355',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking

      inputs: {
        input1: null,
      },
      options: [
        {text: '$\\text{The traditional electron-counting method only}$', value: 'a'},
        {text: '$\\text{Mostly the traditional electron-counting method}$', value: 'b'},
        {text: '$\\text{An equal mix of both methods}$', value: 'c'},
        {text: '$\\text{Mostly the new formal charge method}$', value: 'd'},
        {text: '$\\text{The new formal charge method only}$', value: 'e'},
      ],
    };
  },
};
</script>
